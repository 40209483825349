import Header from "./components/header";
import Footer from "./components/footer";
import Body from "./components/body";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import "./assets/fonts/EuclidCircularASemibold.otf";
import "./assets/fonts/EuclidCircularARegular.otf";
import Modal from "react-modal";
import ReactGA from "react-ga4";

ReactGA.initialize("G-JBJFSKPDNF");
ReactGA.send({
  hitType: "pageview",
  page: window.location.pathname,
  title: window.location.search,
});

Modal.setAppElement("#root");

function App() {
  return (
    <Router>
      <div className="App">
        <Header></Header>
        <Routes>
          <Route exact path="/" element={<Body />} />
        </Routes>
        <Footer></Footer>
      </div>
    </Router>
  );
}

export default App;

