import React, { useState, useCallback, useMemo } from "react";

export default function ContactForm() {
  const initialFormState = useMemo(
    () => ({
      fullName: "",
      email: "",
      message: "",
    }),
    []
  );

  const [formData, setFormData] = useState(initialFormState);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);
  const [acceptedPrivacy, setAcceptedPrivacy] = useState(false);

  const isFormValid = useMemo(() => {
    const { fullName, email, message } = formData;
    return (
      fullName.trim().length >= 2 &&
      email.trim().length >= 5 &&
      message.trim().length >= 10 &&
      acceptedPrivacy
    );
  }, [formData, acceptedPrivacy]);

  // Form reset işlemini optimize et
  const resetForm = useCallback(() => {
    setFormData(initialFormState);
    setAcceptedPrivacy(false);

    if (submitStatus) {
      const timer = setTimeout(() => {
        setSubmitStatus(null);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [submitStatus, initialFormState]);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (!isFormValid) return;

      setIsSubmitting(true);
      setSubmitStatus(null);

      try {
        const response = await fetch(
          "https://europe-west1-easyapp-release.cloudfunctions.net/contactUs",
          {
            method: "POST",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          }
        );

        const data = await response.json();

        if (response.ok && data.success) {
          setSubmitStatus({
            type: "success",
            message: "Thank you! Your message has been sent successfully.",
          });
          resetForm();
        } else {
          throw new Error(data.error || "Failed to send message");
        }
      } catch (error) {
        console.error("Error:", error);
        setSubmitStatus({
          type: "error",
          message:
            error.message || "An error occurred. Please try again later.",
        });
      } finally {
        setIsSubmitting(false);
      }
    },
    [formData, isFormValid, resetForm]
  );

  const handleChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));

      if (submitStatus?.type === "error") {
        setSubmitStatus(null);
      }
    },
    [submitStatus]
  );

  const handlePrivacyChange = useCallback((e) => {
    setAcceptedPrivacy(e.target.checked);
  }, []);

  return (
    <div className="contact-form-container">
      <h3 className="contact-title">Contact Us</h3>
      {submitStatus && (
        <div className={`form-message ${submitStatus.type}`}>
          {submitStatus.message}
        </div>
      )}
      <form onSubmit={handleSubmit} className="contact-form">
        <div className="form-group">
          <input
            type="text"
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
            placeholder="Your Name"
            required
            className="form-input"
            disabled={isSubmitting}
            minLength={2}
            maxLength={50}
            autoComplete="off"
          />
        </div>
        <div className="form-group">
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Your Email"
            required
            className="form-input"
            disabled={isSubmitting}
            autoComplete="off"
          />
        </div>
        <div className="form-group">
          <textarea
            name="message"
            value={formData.message}
            onChange={handleChange}
            placeholder="Your Message"
            required
            className="form-input form-textarea"
            disabled={isSubmitting}
            minLength={10}
            maxLength={1000}
            autoComplete="off"
          />
        </div>
        <div className="privacy-checkbox">
          <label>
            <input
              type="checkbox"
              checked={acceptedPrivacy}
              onChange={handlePrivacyChange}
              disabled={isSubmitting}
            />
            <span>
              I agree to the{" "}
              <a
                href="https://easyapp.ai/privacy_policy_en.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
            </span>
          </label>
        </div>
        <button
          type="submit"
          className="submit-button"
          disabled={isSubmitting || !isFormValid}
        >
          {isSubmitting ? "Sending..." : "Send Message"}
        </button>
      </form>
    </div>
  );
}

