import React from "react";
import "../assets/css/body.css";
import {
  LazyLoadComponent,
  LazyLoadImage,
} from "react-lazy-load-image-component";
import ContactForm from "./ContactForm";

export default function Body() {
  return (
    <div className="body">
      <div className="div-store">
        <a
          href="https://apps.apple.com/tr/app/id1620539343"
          className="store-btn"
          target="_blank"
          rel="norefferer noreferrer"
        >
          <LazyLoadImage
            src="/assets/img/app-store.svg"
            effect="blur"
            alt=""
            className="image-store"
          />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.easyapp.editor"
          className="store-btn"
          target="_blank"
          rel="norefferer noreferrer"
        >
          <LazyLoadImage
            src="/assets/img/google-play.svg"
            effect="blur"
            alt=""
            className="image-store"
          />
        </a>
      </div>
      <h2 className="title">
        Easily build your app on your mobile device <br />
        with no-code and AI-Powered.
      </h2>
      <div style={{ position: "relative" }}>
        <LazyLoadImage
          src="/assets/img/2xmockup.png"
          effect="blur"
          alt=""
          className="image-mockup-desktop"
        />
        <LazyLoadImage
          src="/assets/img/3xmockup.png"
          effect="blur"
          alt=""
          className="image-mockup-mobile"
        />
      </div>

      <ContactForm />
    </div>
  );
}

