import React from "react";
import "../assets/css/footer.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function footer() {
  const currentYear = new Date().getFullYear();
  return (
    <div className="footer-div">
      <LazyLoadImage
        src="/assets/img/2xlogo.png"
        alt=""
        style={{ width: "100px", height: "100px" }}
        className="logo-desktop"
      />
      <LazyLoadImage
        src="/assets/img/3xlogo.png"
        alt=""
        className="logo-mobile"
        style={{ width: "100px", height: "100px" }}
      />

      <div className="footer-div-store">
        <a
          href="https://apps.apple.com/tr/app/id1620539343"
          className="footer-store-btn"
          target="_blank"
          rel="norefferer noreferrer"
        >
          <LazyLoadImage
            src="/assets/img/app-store.svg"
            effect="blur"
            alt=""
            className="footer-image-store"
          />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.easyapp.editor"
          className="footer-store-btn"
          target="_blank"
          rel="norefferer noreferrer"
        >
          <LazyLoadImage
            src="/assets/img/google-play.svg"
            effect="blur"
            alt=""
            className="footer-image-store"
          />
        </a>
      </div>
      <div className="social-div">
        <ul className="footer-ul">
          <li className="footer-li">
            <a
              href="https://www.linkedin.com/showcase/easyappai/"
              className="footer-link"
              target="_blank"
              rel="norefferer noreferrer"
            >
              <LazyLoadImage
                src="/assets/img/linkedin.svg"
                effect="blur"
                alt=""
                className="footer-social-icon"
              />
              Linkedin
            </a>
          </li>
          <li className="footer-li">
            <a
              href="https://twitter.com/easyappai"
              target="_blank"
              className="footer-link"
              rel="noreferrer"
            >
              <LazyLoadImage
                src="/assets/img/twitter.svg"
                effect="blur"
                alt=""
                className="footer-social-icon"
              />
              Twitter
            </a>
          </li>
          <li className="footer-li">
            <a
              href="https://www.instagram.com/easyapp.ai/"
              target="_blank"
              className="footer-link"
              rel="noreferrer"
            >
              <LazyLoadImage
                src="/assets/img/instagram.svg"
                effect="blur"
                alt=""
                className="footer-social-icon"
              />
              Instagram
            </a>
          </li>
        </ul>
      </div>
      <div className="copyright-div">
        <span className="copyright">© 2015 - {currentYear} Taptoweb</span>
      </div>
    </div>
  );
}
